import Vue from 'vue';
import Currency from '~/components/Currency.vue';

Vue.component('currency', Currency);

Vue.mixin({
	methods: {
		async fetch(endpoint, body) {
			if (!body) {
				body = {};
			}
			let response = await fetch(`${this.$store.state.global.api_path}?q=/` + endpoint, {
				method: 'post', body: JSON.stringify(body),
				cache: 'no-store',
			});
			response = await response.json();
			return response;
		},
		async oa_fetch(endpoint, body) {
			if (!body) {
				body = {};
			}
			if (!this.$store.state.session.access_token) {
				const data = localStorage.getItem('session');
				if (data) {
					const json = JSON.parse(data);
					this.$store.commit(`session/set`, json);
				}
			}
			body.access_token = this.$store.state.session.access_token;
			let response = await fetch(`${this.$store.state.global.api_path}?q=/` + endpoint, {
				method: 'post', body: JSON.stringify(body),
				cache: 'no-store',
			}).then(response => response.json());
			if (!response.access_token_expired) {
				return response;
			}
			return await fetch(`${this.$store.state.global.api_path}?q=/authentication/refresh`, {
				method: 'post',
				cache: 'no-store',
				body: JSON.stringify({ refresh_token: this.$store.state.session.refresh_token })
			}).then(response => response.json()).then(response => {
				if (response.error || response.errors) {
					throw new Error('Unable to refresh access token.');
				}
				this.$store.commit('session/refresh', response);
				return this.oa_fetch(endpoint, body);
			}).catch(() => {
				this.$store.commit('session/sign_out');
			});
		},
		round(number, precision) {
			if (precision < 1) {
				return Math.round(number);
			}
			let scale = Math.pow(10, precision);
			return Math.round((number + Number.EPSILON) * scale) / scale;
		},
		validate_quantity(prices, current_quantity) {
			let quantity = parseInt(current_quantity, 10);
			if (!quantity) {
				quantity = 1;
			}
			if (prices.length && quantity < prices[0].min) {
				quantity = prices[0].min;
				return quantity;
			}
			let invalid_quantity = true;
			for (const i in prices) {
				if (prices[i].min == quantity) {
					invalid_quantity = false;
					break;
				}
			}
			if (invalid_quantity) {
				quantity = prices[0].min;
			}
			return quantity;
		},
	},
});