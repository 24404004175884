<template>
	<div class="body column">
		<header class="paper">
			<n-link :to="$store.state.session.signed_in ? '/home': '/'">
				<Logo/>
			</n-link>
		</header>
		<div class="main">
			<div class="page">
				<nuxt/>
			</div>
		</div>
		<Housekeeping/>
	</div>
</template>

<script>
import Logo from '~/components/Logo.vue'
import Housekeeping from '~/components/Housekeeping.vue'

export default {
	components: {
		Logo,
		Housekeeping
	},
	async mounted() {
		switch ( this.$i18n.locale ) {
			case 'default': {
				window.location = '/';
				return;
			}
			case 'us': {
				this.$store.commit('session/set', {
					currency_symbol: "&dollar;",
					currency_code: "USD",
				});
				break;
			}
			case 'eu': {
				this.$store.commit('session/set', {
					currency_symbol: "&euro;",
					currency_code: "EUR",
				});
				break;
			}
		}
	}
}
</script>

<style scoped>
header {
	text-align: center;
}
</style>
