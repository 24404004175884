<template>
	<span>
		<span v-html="$store.state.session.currency_symbol"></span><span v-if="type == 'total'">{{ display_value.integer }}.<span class="price-fraction">{{ display_value.fraction }}</span></span><span v-else>{{ display_value.total }}</span></span>
</template>

<script>
export default {
	props: [
		'value',
		'type'
	],
	computed: {
		display_value() {
			return {
				total: parseFloat(this.value).toLocaleString('en', {minimumFractionDigits: 2}),
				integer: parseInt(this.value).toLocaleString('en'),
				fraction: Math.round(this.value % 1 * 100).toString().padStart(2, "0")
			};
		}
	}
}
</script>

<style scoped>
.price-fraction {
	font-size: 0.7em;
}
</style>